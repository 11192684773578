import Vue from "vue";
import Components from "./components";
import "./app.css";

import jump from 'jump.js'

import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data() {
    return {
      activeNav: false
    };
  },
  methods: {
    jump(e) {
      jump(e.target.getAttribute("href"));
      this.activeNav = false;
    }
  }
});
