<template>
  <span>
    <span v-if="attribute">{{attribute}}</span>
    <span>&nbsp;</span>
    <span style="display:none;" v-for="(attr, index) in attributesFiltered" :key="index">
      {{attr}}
    </span>
  </span>
</template>
<script>
export default {
  props: {
    raw: Array
  },
  data() {
    const attribute = "";
    return {
      attribute,
      test: []
    };
  },
  computed: {
    attributes() {
      return this.raw.map(raw => raw.word);
    },
    attributesFiltered() {
      return this.attributes.filter((val, i) => i > 0);
    }
  },
  mounted() {
    this.attribute = this.attributes[0];
    this.setAttribute();
  },
  methods: {
    setAttribute() {
      const removeChars = resolve => {
        if (this.attribute.length === 0) return resolve();
        setTimeout(() => {
          this.attribute = this.attribute.substring(
            0,
            this.attribute.length - 1
          );
          removeChars(resolve);
        }, 80);
      };
      const typeString = (resolve, string) => {
        if (string.length === 0) return resolve();
        setTimeout(() => {
          this.attribute = this.attribute + string[0];
          typeString(resolve, string.substring(1));
        }, 120);
      };
      setTimeout(() => {
        const index = this.attributes.indexOf(this.attribute) + 1;
        const newIndex = index >= this.attributes.length ? 0 : index;
        const newAttribute = this.attributes[newIndex];
        new Promise(removeChars).then(() => {
          new Promise(resolve => typeString(resolve, newAttribute)).then(() => {
            this.setAttribute();
          });
        });
      }, 3000);
    }
  }
};
</script>